"use client";
import { Suspense, useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import dynamic from "next/dynamic";
import Link from "next/link";
import Image from "next/image";
import ApolloProvider from "@/app/providers/ApolloProvider";
import PageViewEvent from "@/app/lib/PageViewEvent";
import { useResponsive } from "@/app/hooks/useResponsive";
import indexAds from "@/app/components/atoms/AdContainer/AdsConfig";
import {
	ProfileInfo,
	ProfileSP,
} from "@/app/components/organisms/BlockProfileSP/interfaces/ProfileSP.interface";
import style from "@/app/styles/Molecules/StoryPage.module.scss";
import CardStoryPage from "@/app/components/molecules/CardStoryPage/CardStoryPage";
import WidgetTitle from "@/app/components/molecules/WidgetTitle/WidgetTitle";
const Advertising = dynamic(
	() => import("@/app/components/atoms/AdContainer/Advertising")
);
const Breadcrumb = dynamic(
	() => import("@/app/components/molecules/Breadcrumb/Breadcrumb")
);
const CardLayoutVerticalList = dynamic(
	() =>
		import(
			"@/app/components/molecules/CardLayoutVerticalList/CardLayoutVerticalList"
		)
);

type Props = {
	data: ProfileSP | any;
	isVariantCoverage?: boolean;
	isVariantBulletin?: any;
	type?: string;
	position?: Number;
	total?: Number;
	dlData?: any;
};

export default function StoryPage({
	data,
	isVariantCoverage = false,
	isVariantBulletin = false,
	type,
	position,
	total,
	dlData,
}: Props) {
	const termIds = data?.getStoryPages?.term?.map((term: any) => term?.id);
	const typeOfContent = data?.getStoryPages?.type || data?.type;
	const [isReadMore, setIsReadMore] = useState(true);
	const {
		title = null,
		editorialTitle = null,
		metaData,
		path = null,
		teads,
		seedTag,
	} = data?.getStoryPages || data || {};
	const hasAds = false;
	const pathName = usePathname();
	const related = (
		data?.getStoryPages?.relationships?.field_temas?.relationships ||
		data?.relationships?.field_temas?.relationships
	)?.slice(0, 8);
	const asideInfo = data?.getStoryPages?.profileInfo || data?.profileInfo;
	// const hasAds = StoryPage?.relationships?.field_quitar_pauta;

	useEffect(() => {
		setIsReadMore(true);
		if (
			pathName?.includes("preview") ||
			pathName?.includes("/sala-de-prensa/")
		) {
			document.head.title = `${
				metaData?.title ?? editorialTitle ?? title
			} | N+`;
		}
	}, [editorialTitle, metaData?.title, pathName, title]);

	return (
		<>
			{(typeOfContent === "perfiles" ||
				pathName?.includes("/sala-de-prensa/")) && (
				<Suspense>
					<PageViewEvent metadata={dlData} />
				</Suspense>
			)}
			{typeOfContent === "perfiles" ? (
				<section
					className={`${style["storyPage__pageWrapper"] + " storyPage"}`}
				>
					<Breadcrumb
						specialPath={metaData?.title ?? editorialTitle ?? title ?? ""}
						getLasted={true}
					/>
					<div className={`${style["storyPage__contentWrapper"]}`}>
						<CardStoryPage
							isVariantCoverage={isVariantCoverage}
							cardData={data?.getStoryPages || data}
							isVariantBulletin={isVariantBulletin}
							profileData={data}
							type={type}
							position={position}
							total={total}
							adOps={{ hasTeads: teads, hasSeedTag: seedTag }}
						/>
						<aside
							className={`${style["profile__aside"]} ${style["profile__desktop"]}`}
						>
							{asideInfo?.map((info: ProfileInfo, index: number) => (
								<div
									key={info.title}
									className={`${style[`profile__desktop-${index}`]} ${
										style["profile__cardInfo"]
									}`}
								>
									<h2 className={style["profile__title"]}>{info.title}</h2>

									<div className={style["profile__wrapperInfo"]}>
										{Object.entries(info).map(
											([fieldName, field]) =>
												field.text && (
													<p key={fieldName} className={style["profile__info"]}>
														{field.text.includes("https") ? (
															<Link
																href={field.text}
																className={style["profile__urlSocial"]}
															>
																{field.label}
															</Link>
														) : (
															<span className={style["profile__dataTitle"]}>
																{field.label}
															</span>
														)}

														{field.text.includes("https") ? (
															<></>
														) : (
															<>: {field.text}</>
														)}
													</p>
												)
										)}
									</div>
									{info?.img?.url && (
										<div
											className={`${style["profile__desktop__imageWrapper"]}`}
										>
											<Image
												alt={info.img.alt || "nmas"}
												src={info.img.url ?? "/assets/placeholder_.svg"}
												height={100}
												width={306}
												className={`${style["profile__desktop-image"]}`}
											/>
										</div>
									)}
								</div>
							))}
						</aside>
					</div>
				</section>
			) : (
				<section
					className={`${style["storyPage__pageWrapper"] + " storyPage"}`}
				>
					<Breadcrumb
						specialPath={metaData?.title ?? editorialTitle ?? title ?? ""}
						getLasted={true}
					/>
					<div className={`${style["storyPage__contentWrapper"]}`}>
						<CardStoryPage
							isVariantCoverage={isVariantCoverage}
							cardData={data?.getStoryPages || data}
							isVariantBulletin={isVariantBulletin}
							type={type}
							position={position}
							total={total}
							adOps={{ hasTeads: teads, hasSeedTag: seedTag }}
						/>
						<aside className={`${style["storyPage__asideContent"]}`}>
							<WidgetTitle
								title={
									isVariantCoverage
										? "Ver toda la cobertura"
										: isVariantBulletin
										? "Otros boletines"
										: "Relacionados"
								}
								text={
									isVariantCoverage
										? "Cubrimiento minuto a minuto"
										: isVariantBulletin
										? "Aquí puedes consultar otros boletines de interés"
										: "Más contenidos sobre este tema"
								}
								lineColor={"#FF3640"}
							/>
							{pathName?.includes("/sala-de-prensa/") ? (
								<Suspense fallback={<>Cargando</>}>
									<div className={`${style["storyPage__relatedContent"]}`}>
										<ApolloProvider>
											<CardLayoutVerticalList
												cardsData={related}
												isLoadMore={isReadMore}
												isVariantStoryPage={true}
												loadMoreLabel="Cargar más"
												loadMoreSliceUntil={9}
												slice={{ from: 0, to: 4 }}
												widgetTitle={"StoryPage"}
												storyPageTitle={title}
												isVariantBulletin={isVariantBulletin}
												type={type}
												position={position}
												total={total}
											/>
										</ApolloProvider>
									</div>
								</Suspense>
							) : (
								<Suspense fallback={<>Cargando</>}>
									<div className={`${style["storyPage__relatedContent"]}`}>
										<ApolloProvider>
											<CardLayoutVerticalList
												isLoadMore={isReadMore}
												isVariantStoryPage={true}
												loadMoreLabel="Cargar más"
												loadMoreSliceUntil={9}
												slice={{ from: 0, to: 4 }}
												widgetTitle={"StoryPage"}
												storyPageTitle={title}
												isVariantBulletin={isVariantBulletin}
												type={type}
												position={position}
												total={total}
												terms={termIds}
											/>
										</ApolloProvider>
									</div>
								</Suspense>
							)}
							<StoryPageAd
								isVariantCoverage={isVariantCoverage}
								hasAds={hasAds}
							/>
						</aside>
					</div>
				</section>
			)}
		</>
	);
}

function StoryPageAd({ isVariantCoverage, hasAds }: any) {
	const { isDesktop } = useResponsive();
	const pathName = usePathname() || "";

	return (
		!pathName?.includes("/preview/") &&
		!isVariantCoverage &&
		!hasAds && (
			<div className={`${style["storyPage__wrapperAd"]}`}>
				<Advertising
					typeAd={isDesktop ? indexAds.desktop.combo2 : indexAds.mobile.combo}
					urlPath={pathName}
					isAside={true}
				/>
			</div>
		)
	);
}
